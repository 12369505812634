<template>
  <section>
    <v-carousel
      cycle
      hide-delimiter-background
      :show-arrows="false"
      delimiter-icon="mdi-minus"
      :height="sliderHeight"
    >
      <v-carousel-item
        :v-if="sliders"
        v-for="(slider, i) in sliders"
        :key="i"
        :src="slider.img"
      ></v-carousel-item>
    </v-carousel>
    <v-container text-center class="ctk-div">
      <v-layout wrap mb-10>
        <v-flex xs12 md3 mb-2 pa-2 class="ctk-jx-category">
          <v-card class="mx-auto ctk-case-category-card" >
            <a href="/products">
              <v-img
                src="imgs/menu_product.png"
                aspect-ratio="1"
                class="ctk-case-category-img"
              ></v-img>
            </a>
            <div class="ctk-cat-text">
              <h1>产品中心</h1>
            </div>
          </v-card>
        </v-flex>
        <v-flex xs12 md3 mb-2 pa-2 class="ctk-jx-category">
          <v-card class="mx-auto ctk-case-category-card" >
            <a href="/cases">
              <v-img
                src="imgs/menu_case.png"
                aspect-ratio="1"
                class="ctk-case-category-img"
              ></v-img>
            </a>
            <div class="ctk-cat-text">
              <h1>项目案例</h1>
            </div>
          </v-card>
        </v-flex>
        <v-flex xs12 md3 mb-2 pa-2 class="ctk-jx-category">
          <v-card class="mx-auto ctk-case-category-card" >
            <a href="/about">
              <v-img
                src="imgs/menu_about.png"
                aspect-ratio="1"
                class="ctk-case-category-img"
              ></v-img>
            </a>
            <div class="ctk-cat-text">
              <h1>关于我们</h1>
            </div>
          </v-card>
        </v-flex>
        <v-flex xs12 md3 mb-2 pa-2 class="ctk-jx-category">
          <v-card class="mx-auto ctk-case-category-card" >
            <a href="/contact">
              <v-img
                src="imgs/menu_contact.png"
                aspect-ratio="1"
                class="ctk-case-category-img"
              ></v-img>
            </a>
            <div class="ctk-cat-text">
              <h1>联系我们</h1>
            </div>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </section>
</template>

<script>
export default {
  data: () => ({
    sliders: [],
    sliderHeight: 520,
    pageId: 0,
    tabProducts: null,
    tabNews: null,
    tabCases: null,
    innerWidth: window.innerWidth,
    detail: null,
    publish: null
  }),
  created () {
    document.querySelector('#menu_home').classList.add('v-btn--active')
    if (window.innerWidth < 600) {
      this.sliderHeight = 220
    } else if (window.innerWidth < 960) {
      this.sliderHeight = 360
    } else {
      this.sliderHeight = 520
    }
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
        this.$nextTick(function () {
          this.sliders = val.banner[this.pageId]
        })
      },
      deep: true
    }
  },
  computed: {
  },
  methods: {
  }
}
</script>
<style>
</style>
